import React from 'react'
import Layout from '../../components/layout'
import { QuizCreator } from '../../components/ChallengeCreators'

const QuizCreatorPage = ({ challengeId }) => (
  <Layout>
    <QuizCreator challengeId={challengeId} />
  </Layout>
)

export default QuizCreatorPage
